import widgetRoutes from 'enums/routes'
import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Layout from 'library/atoms/Layout'
import styled from '@emotion/styled'
import { TopLevelCard } from 'helpers/determineTopLevelChargeableData'
import { DEFAULT_TITLES } from 'enums/topLevelChargeables'
import { SetActiveModalId } from 'shared/library/molecules/Modal'
import urlPrettier from 'helpers/urlPrettier'
import PoweredByEola from 'library/atoms/PoweredByEola'

export interface MenuProps {
  menuLinks?: TopLevelCard[]
  setActiveModalId: SetActiveModalId<null>
}

const Menu = ({ menuLinks, setActiveModalId }: MenuProps) => {
  const { query, asPath } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const { t } = useTranslation('common')

  const COMMON_PROPS = { onClick: () => setActiveModalId(null) }

  const checkIsActiveLink = (href: string) => {
    return urlPrettier(asPath) === urlPrettier(href)
  }

  return (
    <StyledLayout>
      <StyledNav>
        <Link
          href={widgetRoutes.HOME.url({ widgetSlug })}
          passHref
          legacyBehavior
        >
          <StyledMenuLink
            {...COMMON_PROPS}
            isActiveLink={checkIsActiveLink(
              widgetRoutes.HOME.url({ widgetSlug })
            )}
          >
            {t('home')}
          </StyledMenuLink>
        </Link>
        {menuLinks?.map(({ href, title }) => (
          <Link href={href} key={title} passHref legacyBehavior>
            <StyledMenuLink
              {...COMMON_PROPS}
              isActiveLink={checkIsActiveLink(href)}
            >
              {DEFAULT_TITLES.some(x => x === title)
                ? t(title.toLowerCase())
                : title}
            </StyledMenuLink>
          </Link>
        ))}
      </StyledNav>
      <PoweredByEola />
    </StyledLayout>
  )
}

export default Menu

const StyledNav = styled.nav(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.space[8],
  padding: theme.mixins.pxSpread([theme.space[6], theme.space[4]])
}))

const StyledMenuLink = styled.a<{ isActiveLink: boolean }>(
  ({ theme, isActiveLink }) => ({
    ...theme.fontStyle.h5,
    textDecoration: isActiveLink ? 'underline' : 'none',
    color: theme.colors.primary,
    '&:first-of-type': {
      marginTop: 0
    },
    '&:last-of-type': {
      marginBottom: 0
    },
    '&:active': {
      textDecoration: 'underline'
    }
  })
)

const StyledLayout = styled(Layout)(() => ({
  width: '100%'
}))

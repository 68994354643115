import determineCategorySettings from 'helpers/determineCategorySettings'
import {
  ACTIVITIES_CARD,
  MEMBERSHIPS_CARD,
  RENTALS_CARD,
  VOUCHER_CARD
} from 'enums/topLevelChargeables'
import {
  Schedulables,
  Schedulables_Aggregate,
  Widgets
} from 'shared/presenters/graphqlTypes'
import determineTopLevelChargeableImage from 'helpers/determineTopLevelChargeableImage'
import { CategoryPresenter } from 'shared/presenters/CategoryPresenter'
import { defined, RecursivePartial } from 'shared/presenters/presenter'
import hasVouchers from 'helpers/hasVouchers'
import { RENTALS_TITLE } from 'shared/enums/rentals'
import { ACTIVITIES_TITLE } from 'enums/activities'
import { MEMBERSHIPS_TITLE } from 'enums/memberships'
import { VOUCHERS_TITLE } from 'enums/vouchers'
import { SchedulableTypeEnum } from 'shared/enums/schedulables'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'

export type WidgetWithAliases = Widgets & {
  outlet: {
    rentals_aggregate: Schedulables_Aggregate
    uncategorized_rentals_aggregate: Schedulables_Aggregate
    activities_aggregate: Schedulables_Aggregate
    uncategorized_activities_aggregate: Schedulables_Aggregate
  }
}

export interface TopLevelCard {
  href: string
  title: string
  schedulable: RecursivePartial<Schedulables>
  cardImage: string
}

export type DetermineWidgetVars = {
  widgetSlug: string
  widgetData: WidgetWithAliases
  locale: string
  defaultLocale: string
}

/**
 * We can fetch categories from two places:
 * - Outlet: All categories included by the widget.
 * - Widget: Only the categories the widget has included to show.
 *
 * When groupByCategory is true we need to identify where we should fetch the data from Outlet or Widget.
 * */
const determineCategoriesAvailable = (
  widgetData: WidgetWithAliases
): 'only_include_widget_categories' | 'all' | undefined => {
  const { widgetCategoryIds, categoriesData } =
    determineCategorySettings(widgetData)
  /**
   * Centres can setup categories for outlets. They can also setup categories against widgets.
   * The priority of grouping is such that if the user has specified to group by category and they have categories assigned to
   * widgets then that takes priority over the all categories created by the outlet.
   */
  if (widgetCategoryIds?.length >= 1) {
    return 'only_include_widget_categories' // Filter to use only the ones assigned on the widget settings
  }
  if (categoriesData?.length >= 1) {
    return 'all'
  }

  // Couldn't find any category
  return undefined
}

const getTopLevelChargeableActivity = ({
  widgetData
}: {
  widgetData: WidgetWithAliases
}) => {
  const categoriesAvailable = determineCategoriesAvailable(widgetData)

  // Only show specific schedulables if the widget settings requested for it
  if (categoriesAvailable === 'only_include_widget_categories') {
    return filterDataIncludedOnWidgetSettings(widgetData)[0]?.schedulables?.[0]
  }

  return widgetData?.outlet?.schedulables?.[0]
}

const getTopLevelChargeableRental = ({
  widgetData
}: {
  widgetData: WidgetWithAliases
}) => {
  const rentals = widgetData?.outlet?.schedulables?.filter(
    schedulable => schedulable.type === SchedulableTypeEnum.Rental
  )

  return rentals?.[0]
}

// The widget settings can include only certain categories.
// This function removes the categories not allowed to show.
const filterDataIncludedOnWidgetSettings = (widgetData: WidgetWithAliases) => {
  const { widgetCategoryIds, categoriesData } =
    determineCategorySettings(widgetData)

  return categoriesData.filter(category =>
    widgetCategoryIds.includes(category.id)
  )
}

// Arrange the card data in groups of categories
const getTopLevelChargeableDataWhenFilterByCategories = ({
  widgetSlug,
  widgetData
}: DetermineWidgetVars) => {
  const { categoriesData, widgetCategoryIds } =
    determineCategorySettings(widgetData)

  const categoriesAvailable = determineCategoriesAvailable(widgetData)

  if (!categoriesAvailable) {
    return []
  }

  const categories =
    categoriesAvailable === 'all'
      ? categoriesData
      : filterDataIncludedOnWidgetSettings(widgetData)

  // Don't show categories which contain no bookable schedulables
  const bookableCategories = categories.filter(
    category => category.schedulables?.length > 0
  )

  /**
   * The category data is fetched from two places:
   *
   * - outlets = all the categories this outlet has and holds all necessary data.
   * - categories_widgets = only categories assigned to this widget and has only categories' id.
   *
   * The following code will find the common ids to return the available categories.
   */
  const allCategoriesIds = bookableCategories.map(category => category.id)

  let availableBookableCategories

  if (categoriesAvailable === 'all') {
    availableBookableCategories = bookableCategories
  } else {
    const commonIds = allCategoriesIds.filter(id =>
      widgetCategoryIds.includes(id)
    )
    availableBookableCategories = bookableCategories.filter(category =>
      commonIds.includes(category.id)
    )
  }

  return availableBookableCategories.map(categoryJson => {
    const category = new CategoryPresenter(categoryJson)
    const schedulable = defined(category.schedulables?.[0])
    const backupImage = determineTopLevelChargeableImage({
      outlet: widgetData?.outlet,
      schedulable,
      title: ACTIVITIES_TITLE
    })

    let categoryImage = null
    if (category.image) {
      categoryImage = generateCloudinaryUrl(category.image)
    }

    return {
      href: `/${widgetSlug}/${category.id}`,
      title: category.name(),
      schedulable: schedulable,
      cardImage: categoryImage || backupImage
    }
  })
}

/**
 * We only want to show the rentals card if rentals are available and
 * 1. We aren't showing categories OR
 * 2. We are showing all categories and there are uncategorized rentals
 */
const determineShowRentalsCard = (widgetData: WidgetWithAliases) => {
  const { outlet } = widgetData
  const { filterByCategory } = determineCategorySettings(widgetData)
  const categoriesAvailable = determineCategoriesAvailable(widgetData)
  const showCategories = filterByCategory && categoriesAvailable

  if (
    filterByCategory &&
    categoriesAvailable == 'only_include_widget_categories'
  )
    return

  if (!outlet?.feature_toggles?.rentals_enabled) return

  if (!outlet?.rentals_aggregate?.aggregate?.count) return

  return (
    !showCategories || outlet?.uncategorized_rentals_aggregate?.aggregate?.count
  )
}

/**
 * We only want to show the activities card if
 * 1. Activities are available and we aren't showing categories
 * 2. We are showing all categories and there are uncategorized rentals/activities
 * 3. There are no activities and no rentals, this case is handled within the main method
 */
const determineShowActivitiesCard = (widgetData: WidgetWithAliases) => {
  const { outlet } = widgetData
  const { filterByCategory } = determineCategorySettings(widgetData)
  const categoriesAvailable = determineCategoriesAvailable(widgetData)
  const showCategories = filterByCategory && categoriesAvailable

  if (
    filterByCategory &&
    categoriesAvailable == 'only_include_widget_categories'
  )
    return

  if (!outlet?.activities_aggregate?.aggregate?.count) return

  return (
    !showCategories ||
    outlet?.uncategorized_activities_aggregate?.aggregate?.count
  )
}

/**
 * The homepage (/[widgetSlug]/index.tsx) can load different groups of chargeable, i.e. (Vouchers, Schedulable, Rentals etc.)
 * or it can grouped by categories.
 *
 * Vouchers and memberships are always shown if they exist.
 *
 * See determineShowActivitiesCard and determineShowRentalsCard for the logic behind showing those cards.
 *
 * See getTopLevelChargeableDataWhenFilterByCategories for the logic behind showing category cards.
 *
 * This function determines what should be rendered and formats the data to load the cards on the homepage.
 *
 * Note - these 'cards' might also be referenced as 'top level chargeables' depending on context
 * */
const determineTopLevelChargeableData = ({
  widgetSlug,
  widgetData,
  locale,
  defaultLocale
}: DetermineWidgetVars) => {
  if (!widgetData) return

  const { outlet } = widgetData
  const { filterByCategory } = determineCategorySettings(widgetData)
  const categoriesAvailable = determineCategoriesAvailable(widgetData)
  const showActivitiesCard = determineShowActivitiesCard(widgetData)
  const showRentalsCard = determineShowRentalsCard(widgetData)

  const cards: TopLevelCard[] = []

  if (filterByCategory && categoriesAvailable) {
    cards.push(
      ...getTopLevelChargeableDataWhenFilterByCategories({
        widgetSlug,
        widgetData,
        locale,
        defaultLocale
      })
    )
  }

  if (showActivitiesCard) {
    const schedulable = getTopLevelChargeableActivity({ widgetData }) ?? null
    cards.push(
      ACTIVITIES_CARD({
        widgetSlug,
        cardImage: determineTopLevelChargeableImage({
          outlet: widgetData?.outlet,
          schedulable,
          title: ACTIVITIES_TITLE
        }),
        schedulable
      })
    )
  }

  if (showRentalsCard) {
    cards.push(
      RENTALS_CARD({
        widgetSlug,
        cardImage: determineTopLevelChargeableImage({
          outlet: widgetData?.outlet,
          schedulable: getTopLevelChargeableRental({ widgetData }),
          title: RENTALS_TITLE
        })
      })
    )
  }

  if (hasVouchers(outlet)) {
    cards.push(
      VOUCHER_CARD({
        widgetSlug,
        cardImage: determineTopLevelChargeableImage({
          outlet: widgetData?.outlet,
          title: VOUCHERS_TITLE
        })
      })
    )
  }

  if (outlet?.membership_products?.length > 0) {
    cards.push(
      MEMBERSHIPS_CARD({
        widgetSlug,
        cardImage: determineTopLevelChargeableImage({
          outlet: widgetData?.outlet,
          title: MEMBERSHIPS_TITLE
        })
      })
    )
  }

  return cards
}

export default determineTopLevelChargeableData

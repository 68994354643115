import styled from '@emotion/styled'
import Icon from 'shared/library/atoms/Icon'
import { gql, useQuery } from '@apollo/client'
import { ON_SERVER } from 'shared/enums/environment'
import { useRouter } from 'next/router'
import determineTopLevelChargeableData, {
  TopLevelCard
} from 'helpers/determineTopLevelChargeableData'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import { useEnthusiast } from 'shared/state/useAuth'
import { Text } from 'library/atoms/Typography'
import { OutletPresenter } from 'shared/presenters'
import HeaderModals, {
  ModalProps,
  MODAL_CONFIG,
  MODAL_IDS
} from './HeaderModals'
import useModal from 'shared/state/useModal'
import useBasket from 'state/useBasket'
import { Query_Root } from 'shared/presenters/graphqlTypes'
import { WidgetWithAliases } from 'helpers/determineTopLevelChargeableData'
import usePageRouter from 'shared/state/usePageRouter'
import {
  ShowOnMarketplace,
  HideOnMarketplace
} from 'shared/library/atoms/ConditionalMarketplace'
import widgetRoutes from 'enums/routes'

interface HeaderProps {
  showLogoOnly?: boolean
}

export const BASKET_BUTTON_TESTID = 'basket-button'
export const PROFILE_BUTTON_TESTID = 'profile-button'
export const MENU_BUTTON_TESTID = 'menu-button'
export const CLOSE_MENU_BUTTON_TESTID = 'close-menu-button'

const Header = ({ showLogoOnly }: HeaderProps) => {
  const { t } = useTranslation()
  const { authenticated } = useEnthusiast()
  const { basket } = useBasket()
  const { locale, defaultLocale, query, push } = useRouter()
  const widgetSlug = query.widgetSlug as string

  const { setActiveModalId } = usePageRouter()

  const { data: widgetData } = useQuery<Pick<Query_Root, 'widgets'>>(
    FETCH_CATEGORY_LINKS,
    {
      variables: {
        widgetSlug,
        locale
      },
      skip: ON_SERVER,
      ssr: ON_SERVER
    }
  )

  /**
   * We can use type assertion here because we are sure that widgetJSON has
   * type of WidgetWithAliases because it also affects rentals aggregate
   */
  const widgetJSON = widgetData?.widgets?.[0] as WidgetWithAliases
  const basketItemsNumber = basket?.line_items_aggregate?.aggregate?.count ?? 0
  const outlet = widgetJSON?.outlet && new OutletPresenter(widgetJSON?.outlet)
  const outletLogo = outlet && outlet?.logoImageURL()

  const menuLinks =
    widgetJSON &&
    determineTopLevelChargeableData({
      widgetData: widgetJSON,
      widgetSlug: widgetSlug as string,
      locale: locale as string,
      defaultLocale: defaultLocale as string
    })

  return (
    <>
      <StyledHeader>
        <StyledHeaderContainer>
          <ShowOnMarketplace>
            <Icon name="logo" size={70} color="grey" />
          </ShowOnMarketplace>
          <HideOnMarketplace>
            {outletLogo && (
              <StyledLogo
                width="150"
                height="40"
                src={outlet.logoImageURL()}
                alt={t('outlets:logo', { outletName: outlet.name ?? '' })}
              />
            )}
          </HideOnMarketplace>
          {!showLogoOnly && (
            <StyledHeaderIconContainer>
              {outlet?.feature_toggles?.shopping_basket_enabled && (
                <Button
                  data-testid={BASKET_BUTTON_TESTID}
                  variant="icon"
                  aria-label={t('common:basket')}
                  onClick={() => push(widgetRoutes.BASKET.url({ widgetSlug }))}
                >
                  <Icon size={24} name="cart" />
                  {basketItemsNumber > 0 && (
                    <StyledInfoAboveIcon>
                      <StyledTextInfoAboveIcon font="heading" as="span">
                        {basketItemsNumber}
                      </StyledTextInfoAboveIcon>
                    </StyledInfoAboveIcon>
                  )}
                </Button>
              )}
              <Button
                data-testid={PROFILE_BUTTON_TESTID}
                variant="icon"
                aria-label={
                  authenticated ? t('common:profile') : t('common:account')
                }
                onClick={() =>
                  setActiveModalId(
                    authenticated ? MODAL_IDS.signOut : MODAL_IDS.signIn
                  )
                }
              >
                <Icon
                  size={24}
                  name="profile"
                  title={
                    authenticated ? t('common:profile') : t('common:account')
                  }
                />
                {authenticated && (
                  <StyledInfoAboveIcon>
                    <Icon size={10} name="check" color="white" />
                  </StyledInfoAboveIcon>
                )}
              </Button>
              <Button
                data-testid={MENU_BUTTON_TESTID}
                variant="icon"
                aria-label={t('common:menu')}
                onClick={() => setActiveModalId(MODAL_IDS.menu)}
              >
                <Icon size={24} name="menu" />
              </Button>
            </StyledHeaderIconContainer>
          )}
        </StyledHeaderContainer>
      </StyledHeader>
      {menuLinks && outlet && !showLogoOnly && (
        <HeaderModalsRenderer menuLinks={menuLinks} />
      )}
    </>
  )
}

export default Header

/**
 * Calls useModal and renders HeaderModals.
 * Isolated in its own component to assist conditional rendering.
 * Prevents error for when query falls over and data not available.
 */
const HeaderModalsRenderer = ({ menuLinks }: { menuLinks: TopLevelCard[] }) => {
  const { activeModal, setActiveModalId, currentModalProps } =
    useModal<ModalProps>({
      MODAL_CONFIG,
      modalPropVariations: {
        menu: {
          menuLinks
        },
        headerSignIn: {},
        headerAuthenticated: {},
        headerSignOut: {},
        headerSignUp: {}
      }
    })

  return (
    <HeaderModals
      activeModal={activeModal}
      setActiveModalId={setActiveModalId}
      {...currentModalProps}
    />
  )
}

export const FETCH_CATEGORY_LINKS = gql`
  query WidgetHeaderFetchMenuCategoryLinks(
    $widgetSlug: String
    $locale: String!
  ) {
    widgets(where: { slug: { _eq: $widgetSlug } }) {
      filter_by_category
      outlet {
        id
        name
        enthusiast_cancellation_policy
        logo_image
        default_currency
        translations(
          where: {
            language: { _in: [$locale, "en"] }
            field: {
              _in: [
                "custom_cancellation_policy_tagline"
                "custom_cancellation_policy_text"
              ]
            }
            model_type: { _eq: "Outlet" }
          }
        ) {
          field
          id
          language
          value_scrubbed
        }
        # Basket
        class_pass_key_image
        voucher_key_image
        credit_voucher_image
        # End - Basket
        categories {
          id
          translations(
            where: {
              language: { _in: [$locale, "en"] }
              field: { _in: ["name"] }
              model_type: { _eq: "Category" }
            }
          ) {
            value_scrubbed
            field
            language
          }
          schedulables(
            limit: 1
            where: {
              materialized_bookability_by_days: {
                hide_from_public: { _eq: false }
              }
            }
          ) {
            id
          }
        }
        feature_toggles {
          credit_vouchers_enabled
          class_passes_enabled
          rentals_enabled
          memberships_enabled
          shopping_basket_enabled
        }
        activities_aggregate: schedulables_aggregate(
          where: {
            materialized_bookability_by_days: {
              hide_from_public: { _eq: false }
              schedulable_type: { _eq: "Activity" }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        uncategorized_activities_aggregate: schedulables_aggregate(
          where: {
            materialized_bookability_by_days: {
              hide_from_public: { _eq: false }
              schedulable_type: { _eq: "Activity" }
            }
            _not: { category: {} }
          }
        ) {
          aggregate {
            count
          }
        }
        rentals_aggregate: schedulables_aggregate(
          where: {
            materialized_bookability_by_days: {
              hide_from_public: { _eq: false }
              schedulable_type: { _eq: "Rental" }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        uncategorized_rentals_aggregate: schedulables_aggregate(
          where: {
            materialized_bookability_by_days: {
              hide_from_public: { _eq: false }
              schedulable_type: { _eq: "Rental" }
            }
            _not: { category: {} }
          }
        ) {
          aggregate {
            count
          }
        }
        class_passes_aggregate(
          where: {
            hide_from_public: { _eq: false }
            discarded_at: { _is_null: true }
          }
        ) {
          aggregate {
            count
          }
        }
        membership_products {
          id
        }
      }
      categories_widgets {
        category {
          id
        }
      }
    }
  }
`

export const StyledHeader = styled.div(({ theme }) => ({
  width: '100%',
  borderBottom: theme.border,
  paddingRight: theme.space[4],
  paddingLeft: theme.space[4],
  background: theme.colors.white,
  [theme.mediaQueries.lg]: {
    marginBottom: theme.space[0]
  }
}))

export const StyledHeaderContainer = styled.header(({ theme }) => ({
  minHeight: theme.header.height,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  ...theme.mixins.centeredContainer(theme.currentMaxWidth)
}))

export const StyledHeaderIconContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  '> *': {
    marginLeft: theme.space[3]
  },
  [theme.mediaQueries.sm]: {
    '> *': {
      marginLeft: theme.space[5]
    }
  }
}))

const StyledInfoAboveIcon = styled.div(({ theme }) => ({
  position: 'absolute',
  overflow: 'hidden',
  top: '50%',
  right: '-50%',
  transform: 'translate(0,-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 16,
  width: 16,
  background: theme.colors.primary,
  borderRadius: '50%',
  svg: {
    zIndex: 2,
    'button:hover &': {
      use: {
        color: theme.colors.white
      }
    }
  }
}))

const StyledTextInfoAboveIcon = styled(Text)(({ theme }) => ({
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  color: theme.colors.white,
  fontSize: 10 // exception case not covered by theme.fontSizes
}))

const StyledLogo = styled.img(({ theme }) => ({
  height: 'auto',
  width: 'auto',
  maxHeight: 30,
  [theme.mediaQueries.xs]: {
    maxHeight: 40
  }
}))
